import React, { Component } from "react";

class FormInput extends Component {
  constructor(props) {
    super(props);
    this.state = { val: props.val };
    this.k = this.props.label + "-" + (Math.random() * 10000).toString();
  }

  handleChange = event => {
    this.setState({ val: event.target.value });
    this.props.setVal(event.target.value);
  };

  render() {
    return (
      <label>
        {this.props.label}:
        <input
          key={this.k}
          type="text"
          value={this.state.val}
          onChange={this.handleChange}
        />
      </label>
    );
  }
}

export default FormInput;

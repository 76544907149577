import React, { Component } from "react";
import Search from "./Search";

class AutoCompleteServiceComponent extends Component {
  render() {
    return (
      <>
        <Search />
      </>
    );
  }
}

export default AutoCompleteServiceComponent;

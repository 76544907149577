import React from "react";
import ObjectJson2Table from "./ObjectJson2Table";
import "./Json2Table.css";

export default function Json2Table(props) {
  return (
    <div className="json-2-table">
      <ObjectJson2Table data={props.json} />
    </div>
  );
}

import axios from "axios";

const proxyUrl = "https://cors-anywhere.herokuapp.com/";
const apiUrl = "https://api.yelp.com/v3/";
const apiKey =
  "FVWs1uNwqKI1U4iYtKGntmPQCy9-ZeXQ29q3ZzgwQO3CFiwI7IzXqm3DvCwnaeubLIvRsnsTmZ3rHcD7Rwpjpi9SaRlSJ_mvqzbofZ1NY0cnmpLRDMuP809U8_JCXXYx";

export const RequestHelper = {
  get: (path, params) => {
    let options = {
      headers: {
        Authorization: "Bearer " + apiKey
      },
      params: params
    };
    return axios
      .get(proxyUrl + apiUrl + path, options)
      .then(function(response) {
        console.log(response.data);
        return response.data;
      })
      .catch(function(error) {
        console.log(error.data);
        return error.data;
      });
  },

  post: (path, data) => {
    return axios
      .post(proxyUrl + apiUrl + path + "?apiKey=" + apiKey, data)
      .then(function(response) {
        console.log(response.data);
        return response.data;
      })
      .catch(function(error) {
        console.log(error.data);
        return error.data;
      });
  }
};

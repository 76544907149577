import React from "react";
import SimpleJson2TableTr from "./SimpleJson2TableTr";

export default function ObjectJson2Table(props) {
  let data = props.data;
  let rows = GetSimpleRows(data);

  return (
    <table>
      <tbody>{rows}</tbody>
    </table>
  );
}

function GetSimpleRows(objectData) {
  let rows = [];
  if (objectData) {
    for (const [key, value] of Object.entries(objectData)) {
      let val = null;
      if (key) {
        // console.log(key, value);
        val = value === null ? "null" : value;
        val = value === undefined ? "undefined" : value;

        let itIsSimpleValue = false;

        if (typeof val === "string") itIsSimpleValue = true;
        if (typeof val === "number") itIsSimpleValue = true;
        if (typeof val === "boolean") {
          itIsSimpleValue = true;
          val = val.toString();
        }

        if (itIsSimpleValue) {
          // simple json row

          rows.push(
            <SimpleJson2TableTr
              key={"sj2ttr-" + (Math.random() * 1000000).toString()}
              data={{ k: key, v: val }}
            />
          );
        } else {
          if (Array.isArray(val)) {
            // array
            rows.push(
              <tr key={"sj2ttr-array-" + (Math.random() * 1000000).toString()}>
                <td colSpan="2">
                  <div>
                    <strong>{key}</strong>
                  </div>
                  <table className="json-2-table-child">
                    <tbody>{GetSimpleRows(val)}</tbody>
                  </table>
                </td>
              </tr>
            );
          } else {
            // object
            rows.push(
              <tr key={"sj2ttr-obj-" + (Math.random() * 1000000).toString()}>
                <td colSpan="2">
                  <div>
                    <strong>{key}</strong>
                  </div>
                  <table className="json-2-table-child">
                    <tbody>{GetSimpleRows(val)}</tbody>
                  </table>
                </td>
              </tr>
            );
          }
        }
      }
    }
  }
  return rows;
}

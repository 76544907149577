import React, { Component } from "react";
import { BusinessesCompleteServices } from "../../api/BusinessesCompleteServices";
import RequestComponent from "../../../components/RequestComponent";

class Search extends Component {
  submitText = "businessesSearch";
  parameters = [
    { paramKey: "term", paramVal: "restaurants" },
    { paramKey: "latitude", paramVal: "38.428925" },
    { paramKey: "longitude", paramVal: "27.144849" },
    { paramKey: "locale", paramVal: "tr_TR" }
  ];

  doRequest = paramStates => {
    return BusinessesCompleteServices.businessesSearch(
      paramStates.term,
      paramStates.latitude,
      paramStates.longitude,
      paramStates.locale
    );
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default Search;

import React, { Component } from "react";
import FormInput from "./FormInput";
// import { JsonToTable } from "react-json-to-table";
import Json2Table from "../Json2Table/Json2Table";

class RequestComponent extends Component {
  states;
  k = "";
  constructor(props) {
    super(props);
    this.states = props.parameters.map(p => ({ [p.paramKey]: p.paramVal }));
    if (this.states.length !== 0) this.state = Object.assign(...this.states);
    else this.state = { data: {} };
    this.k = "form-" + (Math.random() * 10000).toString();
  }

  getFormInputs = () => {
    if (this.states.length === 0) return <></>;
    return Object.entries(this.state)
      .filter(o => o[0] !== "data")
      .map(o => (
        <FormInput
          key={o[0] + "--" + this.k}
          label={o[0]}
          val={this.state[o[0]]}
          setVal={val => {
            this.setState({ [o[0]]: val });
          }}
        />
      ));
  };

  doRequest = e => {
    this.setState({ data: {} });
    this.props.doRequest(this.state).then(res => {
      this.setData(res);
    });
    e.preventDefault();
  };

  setData = resData => {
    let d = {};
    // console.log(resData);
    for (const [key, value] of Object.entries(resData)) {
      if (key) {
        // console.log(key, value);
        window.uu = resData;
        if (value === null) {
          d[key] = "null";
        } else {
          d[key] = typeof value === "boolean" ? value.toString() : value;
        }
      }
    }

    this.setState({
      data: d
    });
  };

  render() {
    var formInputs = this.getFormInputs();
    return (
      <>
        <form onSubmit={this.doRequest} key={this.k}>
          {formInputs}
          <input type="submit" value={this.props.submitText} />
        </form>
        <Json2Table
          // key={Math.ceil(Math.random() * 100000000000)}
          json={this.state.data}
        />
      </>
    );
  }
}

export default RequestComponent;

import React from "react";
// import RequestComponentTest from "./components/RequestComponentTest"
import AutoCompleteServiceComponent from "./yelp/components/AutoCompleteServiceComponent";
import BusinessesServiceComponent from "./yelp/components/BusinessesServiceComponent";
// import SimpleJson2TableTest from "./Json2Table/SimpleJson2TableTest";
// import Json2TableTest from "./Json2Table/Json2TableTest";

// import ObjectJson2TableTest from "./Json2Table/ObjectJson2TableTest";

import "./App.css";

function App() {
  return (
    <div className="App">
      <p />
      {/* <Json2TableTest /> */}
      <AutoCompleteServiceComponent />
      <BusinessesServiceComponent />
    </div>
  );
}

export default App;

import React, { Component } from "react";
import { AutoCompleteServices } from "../../api/AutoCompleteServices";
import RequestComponent from "../../../components/RequestComponent";

class Search extends Component {
  submitText = "autocomplete";
  parameters = [
    { paramKey: "text", paramVal: "ust" },
    { paramKey: "latitude", paramVal: "38.428925" },
    { paramKey: "longitude", paramVal: "27.144849" },
    { paramKey: "locale", paramVal: "tr_TR" }
  ];

  doRequest = paramStates => {
    return AutoCompleteServices.search(
      paramStates.text,
      paramStates.latitude,
      paramStates.longitude,
      paramStates.locale
    );
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default Search;

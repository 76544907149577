import { RequestHelper } from "./RequestHelper";

export const AutoCompleteServices = {
  search: (
    text, // Required. Text to return autocomplete suggestions for.
    latitude, // Required if want to get autocomplete suggestions for businesses. Latitude of the location to look for business autocomplete suggestions.
    longitude, // Required if want to get autocomplete suggestions for businesses. Longitude of the location to look for business autocomplete suggestions.
    locale = "en_US" // Optional. Specify the locale to return the autocomplete suggestions in. See the list of supported locales. Defaults to en_US.
  ) =>
    RequestHelper.get("autocomplete", {
      text,
      latitude,
      longitude,
      locale
    })
};

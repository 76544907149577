import { RequestHelper } from "./RequestHelper";

export const BusinessesCompleteServices = {
  businessesSearch: (
    term, // Optional. Search term, for example "food" or "restaurants". The term may also be business names, such as "Starbucks". If term is not included the endpoint will default to searching across businesses from a small number of popular categories.
    latitude, // Required if want to get autocomplete suggestions for businesses. Latitude of the location to look for business autocomplete suggestions.
    longitude, // Required if want to get autocomplete suggestions for businesses. Longitude of the location to look for business autocomplete suggestions.
    locale = "en_US" // Optional. Specify the locale to return the autocomplete suggestions in. See the list of supported locales. Defaults to en_US.
  ) =>
    RequestHelper.get("businesses/search", {
      term,
      latitude,
      longitude,
      locale
    })
};

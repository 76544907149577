import React from "react";

export default function SimpleJson2TableTr(props) {
  return (
    <tr>
      <td>
        <strong>{props.data.k}</strong>
      </td>
      <td>{props.data.v}</td>
    </tr>
  );
}

import React, { Component } from "react";
import Search from "./Search";

class BusinessesServiceComponent extends Component {
  render() {
    return (
      <>
        <Search />
      </>
    );
  }
}

export default BusinessesServiceComponent;
